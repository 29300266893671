import api, { fileUpload } from "@/assets/js/api";
import ApiConfig from "@/config/ApiConfig";
import { VUE_APP_UCENTER } from "@/assets/js/stationSetting";

class UCenterService {
  updateUserInfo(data = {}) {
    return this.fetch(ApiConfig.updateUserInfo, data);
  }

  updateUserInfoV4(data = {}) {
    return this.fetch(ApiConfig.updateUserInfoV4, data);
  }

  getUserInfo(data = {}) {
    return this.fetch(ApiConfig.getUserInfo, data);
  }

  getUserInfoV4(data = {}) {
    return this.fetch(ApiConfig.getUserInfoV4, data);
  }

  userCenterConfig(data = {}) {
    return this.fetch(ApiConfig.userCenterConfig, data);
  }

  updateUserCenterFastConfig(data) {
    return this.fetch(ApiConfig.updateUserCenterFastConfig, data);
  }

  uploadFile(data) {
    const file = data.file;
    delete data.file;
    const formData = new FormData();
    formData.append("type", data.type);
    formData.append("file", file);
    formData.append("app_id", EnvConfig.APP_ID);

    return fileUpload(
      `${EnvConfig.APP_UCENTER}${ApiConfig.uploadFile}`,
      formData
    );
  }

  safetyVerifyGoogleCode(data = {}) {
    return this.fetch(ApiConfig.safetyVerifyGoogle, data);
  }

  safetyVerifyPhoneCode(data) {
    return this.fetch(ApiConfig.safetyVerifyMobile, data);
  }

  safetyVerifyEmailCode(data) {
    return this.fetch(ApiConfig.safetyVerifyEmail, data);
  }

  bindMobileCode(data = {}) {
    return this.fetch(ApiConfig.bindMobileCodeV4, data);
  }
  changeMobileCode(data = {}) {
    return this.fetch(ApiConfig.changeMobileCodeV4, data);
  }

  bindMobile(data = {}) {
    return this.fetch(ApiConfig.bindMobileV4, data);
  }

  changeBindMobile(data = {}) {
    return this.fetch(ApiConfig.changeBindMobileV4, data);
  }

  bindEmailCode(data = {}) {
    return this.fetch(ApiConfig.bindEmailCodeV4, data);
  }

  changeEmailCode(data = {}) {
    return this.fetch(ApiConfig.changeEmailCodeV4, data);
  }

  bindEmail(data = {}) {
    return this.fetch(ApiConfig.bindEmailV4, data);
  }

  changeBindEmail(data = {}) {
    return this.fetch(ApiConfig.changeBindEmailV4, data);
  }

  getVerityEmailCode(data = {}) {
    return this.fetch(ApiConfig.getVerifyEmailCode, data);
  }

  getVerifyMobileCode(data = {}) {
    return this.fetch(ApiConfig.getVerifyMobileCode, data);
  }

  bindGoogle(data = {}) {
    return this.fetch(ApiConfig.bindGoogleV4, data);
  }

  changeBindGoogle(data = {}) {
    return this.fetch(ApiConfig.changeBindGoogleV4, data);
  }

  getGoogleSecret(data = {}) {
    return this.fetch(ApiConfig.getGoogleSecretV4, data);
  }

  updatePassword(data = {}) {
    return this.fetch(ApiConfig.updatePasswordV4, data);
  }

  setTradePassword(data = {}) {
    return this.fetch(ApiConfig.setTradePasswordV4, data);
  }

  updateTradePassword(data = {}) {
    return this.fetch(ApiConfig.resetTradePasswordV4, data);
  }

  openGoogle(data = {}) {
    return this.fetch(ApiConfig.openGoogleV4, data);
  }

  closeGoogle(data = {}) {
    return this.fetch(ApiConfig.closeGoogleV4, data);
  }

  getRegisterPhoneCode(data = {}) {
    return this.fetch(ApiConfig.getRegisterPhoneCode, data);
  }

  getRegisterEmailCode(data = {}) {
    return this.fetch(ApiConfig.getRegisterEmailCode, data);
  }

  checkMobile(data = {}) {
    return this.fetch(ApiConfig.checkMobile, data);
  }

  checkEmail(data = {}) {
    return this.fetch(ApiConfig.checkEmail, data);
  }

  checkMobileCode(data = {}) {
    return this.fetch(ApiConfig.checkMobileCode, data);
  }

  checkEmailCode(data = {}) {
    return this.fetch(ApiConfig.checkEmailCode, data);
  }

  registerMobile(data = {}) {
    return this.fetch(ApiConfig.registerByMobileV4, data);
  }
  registerEmail(data = {}) {
    return this.fetch(ApiConfig.registerByEmailV4, data);
  }

  registerAccount(data = {}) {
    return this.fetch(ApiConfig.registerByAccountV4, data);
  }

  checkLoginName(data = {}) {
    return this.fetch(ApiConfig.checkLoginName, data);
  }

  checkPassword(data = {}) {
    return this.fetch(ApiConfig.checkPassword, data);
  }

  checkSafetyVerify(data = {}) {
    return this.fetch(ApiConfig.checkSafetyVerify, data);
  }

  login(data = {}) {
    return this.fetch(ApiConfig.loginV4, data);
  }

  logout(data = {}) {
    return this.fetch(ApiConfig.logoutV4);
  }

  checkUniversal(data = {}) {
    return this.fetch(ApiConfig.checkUniversal, data);
  }

  checkForgetSafetyVerify(data = {}) {
    return this.fetch(ApiConfig.checkForgetSafetyVerify, data);
  }

  resetPasswordByForget(data = {}) {
    return this.fetch(ApiConfig.resetPasswordByForget, data);
  }

  checkAccount(data = {}) {
    console.log("VUE_APP_UCENTER", VUE_APP_UCENTER);
    return this.fetch(ApiConfig.checkAccount, data);
  }


  fetch(url, data = {}) {
    return api({
      url: `${VUE_APP_UCENTER}${url}`,
      data,
    });
  }
}

export const UCenterRequest = new UCenterService();
